.main-table {
  padding: 30px 0px;
}
.head-btn {
  padding: 20px 0px;
  text-align: right;
}
.bottom-btn {
  padding: 20px 0px;
  text-align: right;
}